@layer components {
    .wysiwyg a,
    .wysiwyg a:visited,
    .wysiwyg a:active,
    .wysiwyg a:focus,
    .wysiwyg a:hover {
        @apply text-primary;
        text-decoration: underline;
    }

    .wysiwyg ul,
    .wysiwyg ol {
        padding-left: 1.5rem;
    }

    .wysiwyg ul {
        list-style: disc;
    }

    .wysiwyg ul ul {
        list-style: circle;
    }

    .wysiwyg ul ul ul {
        list-style-type: "→";
    }

    .wysiwyg ol {
        list-style: decimal;
    }

    .wysiwyg img {
        @apply rounded-lg;
    }

    .wysiwyg table {
        width: 100%;
    }

    .wysiwyg th {
        text-align: left;
    }

    .wysiwyg tr:nth-of-type(2n) {
        @apply bg-grey-200;
    }
}
